<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row class="bradcrumb">
            <router-link :to="{name: 'home' }" class="root">
                Dashboard
            </router-link>
            <!-- /

            <router-link :to="{name: 'rolelink' }" class="root">
                Librarian
            </router-link> -->
            /
            <router-link :to="{name: 'management-reports' }" class="root">
                Reports
            </router-link>
            /
            <span class="child">Member Wise Library Utilization Report </span>
        </v-row>
        <br />

        <v-card class="card">
            <v-card-title class="heading justify-center"> Member Wise Library Utilization Report</v-card-title>

            <div class="checkbox">
                <v-row style="margin:0px 10px 0px 10px">
                    <v-col><v-checkbox v-model="selected" label="Member Name" value="memberName"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="Out Date" value="outDate"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="Books Issued Count" value="bookIssuedCount"></v-checkbox></v-col>
                    <v-col><v-checkbox v-model="selected" label="Organization" value="org"></v-checkbox></v-col>
               
                </v-row >
               <!-- <center> <v-btn dense color="info" @click="fetch" style="margin:10px">Fetch</v-btn></center> -->
            </div>


            <div class="">
                <div class="add-section">
                    <div class="d-flex">
                        <v-col>
                            <label>Organization</label>

                            <v-combobox v-model="selected_org" placeholder="Select Organization" class="text" :items="orgList" item-text="name" item-value="id" outlined return-object dense :rules="[rules.required]" clearable required ></v-combobox>
                        </v-col>
                        <v-col>
                            <label class="add-text">Department</label>{{dept}}
                            <v-autocomplete v-model="dept" placeholder="Select Department" :items="dept_data" item-text="name" item-value="id" outlined dense class="text" :rules="[rules.required]" clearable required></v-autocomplete>
                        </v-col>
                        <v-col>
                            <label>Start Date</label>

                            <v-menu v-model="fromDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field class="text" v-model="startDate" placeholder="Select Start Date" append-icon="event" persistent-hint dense readonly outlined v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="startDate" no-title @input="fromDateMenu = false"></v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col>
                            <label>End Date</label>

                            <v-menu v-model="fromDateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field class="text" v-model="endDate" placeholder="Select End Date" append-icon="event" persistent-hint dense readonly outlined v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="endDate" no-title @input="fromDateMenu1 = false"></v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col>
                            <label class="add-text">User Type</label>
                            <v-autocomplete v-model="usertype" placeholder="Select User Type" :items="usertype_data" item-text="name" item-value="id" outlined class="text" dense :rules="[rules.required]" clearable required></v-autocomplete>
                        </v-col>
                    </div>

                    <div>
                        <center>
                            <v-btn color="success" class="mb-4" @click="memberWise1">Fetch Data</v-btn>
                        </center>
                    </div>
                </div>
                <div class="d-btn" v-if="memberWise.length > 0">
                    <div>
                        <v-btn color="primary" @click="generatePDF"><span class="mdi mdi-download mr-2" style="font-size: 20px;"></span> Download Report</v-btn>
                    </div>
                </div>
                <v-data-table v-if="memberWise.length > 0" v-model="selected" @current-items="getFiltered" :headers="headers" :items="filteredDesserts" item-key="name" class="elevation-1">
                    <v-spacer></v-spacer>

                    <template v-slot:top>
                        <tr class="t-row">
                            <div>
                                <th class="d-flex">
                                    <span v-for="header in headers" :key="header.text">
                                        <span v-if="filters.hasOwnProperty(header.value)">
                                            <v-autocomplete class="m-2" flat hide-details small dense outlined :label="header.text" multiple clearable :items="columnValueList(header.value)" v-model="filters[header.value]"></v-autocomplete>
                                        </span>
                                    </span>
                                </th>
                            </div>
                        </tr>
                    </template>
                    <template v-slot:item.author="{ item }">
                        <span v-for="author in item.authors" :key="author">{{author}}<br /></span>
                    </template>
                </v-data-table>
            </div>
        </v-card>
    </div>
</template>

<script>
    import axios from "axios";
    import moment from "moment";
    import { jsPDF } from "jspdf";
    import "jspdf-autotable";

    import "@/assets/css/color.css"; // css needs to be imported for each dashboard
    export default {
        data: () => ({
            dept: null,
            selected_org:null,
            orgList:[],
            fromDateMenu1: false,
            usertype: null,
            valid: false,
            overlay: false,
            userid: "",
            bookitemid: "",
            selected:[],
            errorMsg: {
                roleLinkId: false,
            },
            rules: {
                required: (value) => !!value || "Required.",
            },
            snackbar: false,
            snackbar_msg: "",
            org: "",
            dialog: false,
            fromDateMenu: false,
            fromDateVal: [],
            startDate: "",
            endDate: "",
            memberWise: [],
            dept_data: [],
            usertype_data: [],
            minDate: "2020-01-05",
            maxDate: new Date(),
            dept_name: "",
            user_type: "",
       
            csv_header_data:[],
            table_header_data:[],
            filters: {
                bookIssuedCount: [],
member_name:[],
outDate: []

            },
            colData:{}
        }),
        computed: {
            fromDateDisp() {
                return this.fromDateVal;
                // format/do something with date
            },
            filteredDesserts() {
                return this.memberWise.filter((d) => {
                    return Object.keys(this.filters).every((f) => {
                        return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
                    });
                });
            },
            dateRangeText() {
                return this.dates.join(" ~ ");
            },
        },

        mounted() {
            this.onLoad();
        },
        methods: {
            getFiltered(e) {
                console.log(e);
                if(e?.length == 0)
                {
                    this.showSnackbar("#b71c1c", "No Record matching with your input/ filter"); 
                }
                this.filterArr = e;
            },
            onlyUnique(value, index, self) {
                if(value != null && value != "" && value)
                    return self.indexOf(value) === index;
            },

            columnValueList(val) {
                var data = this.bookList.map((d) => d[val]);
                data = data.filter(this.onlyUnique);
                return data
            },

            generatePDF() {
                const columns = this.csv_header_data
                const doc = new jsPDF({
                    orientation: "l",
                    unit: "in",
                    format: "letter",
                });

                // text is placed using x, y coordinates
                //   var img = 'https://www.vierp.in/logo/edu+camp-01.png'
                //   doc.addImage(img,'PNG', 0, 0, 180, 160);
                doc.setFontSize(10).text("Pimpri Chinchwad Education Trust", 0.5, 0.3);
                doc.setFontSize(12).text("Pimpri Chinchwad College of Engineering", 0.5, 0.5);
                doc.setFontSize(12).text("Central Library", 0.5, 0.7);

                doc.setFontSize(9).text("Record No", 8, 0.3);
                doc.setFontSize(9).text("Revision", 8, 0.4);
                doc.setFontSize(9).text("Date", 8, 0.5);
                doc.setFontSize(10).text("Department :" + this.dept_name, 8, 0.8);
                doc.setFontSize(10).text("User Type : " + this.user_type, 8, 1);

                let d1 = moment(String(this.startDate)).format("DD-MM-YYYY");
                let d2 = moment(String(this.endDate)).format("DD-MM-YYYY");

                // doc.setFontSize(12).text("Over Dues Members List - Reports - On Yearly Basis Between"+ this.startDate+'and'+ this.endDate, 0.5, 1.0);
                doc.setTextColor("blue").text("Over Dues Members List - Reports - On Yearly Basis Between " + " " + d1 + " " + "and" + " " + d2, 0.5, 1.0);
                doc.setLineWidth(0.01).line(0.5, 1.1, 10.4, 1.1);
                doc.autoTable({
                    columns,
                    body: this.filteredDesserts,
                    margin: { left: 0.5, top: 1.25 },
                });

                doc.setTextColor(0, 0, 255).save(`Over Dues Members List.pdf`);
            },

            memberWise1() {
                this.overlay = true;
let abc = Object.assign({}, this.selected);

for(let i = 0;i<this.selected.length;i++){
    let key = abc[i]
     this.colData[key] = key
}




                if (this.startDate == null || this.endDate == null || this.usertype == null || this.dept == null || this.selected_org == null) {
                    this.showSnackbar("#b71c1c", "All fields are required!!");
                } else {
                    const data = {
                        firstDate: moment(String(this.startDate)).format("DD-MM-YYYY"),
                        lastDate: moment(String(this.endDate)).format("DD-MM-YYYY"),
                        dept_id: this.dept,
                        user_id: this.usertype,
                        colData: this.colData,
                        org:this.selected_org.id
                    };
                    axios
                        .post("/ManagementAdmin/memberWise", data)
                        .then((res) => {
                            if (res.data.msg == "200") {
                                this.overlay = false;
                                this.memberWise = res.data.overdue_data;
                                this.dept_name = res.data.dept;
                                this.user_type = res.data.usertype;
                                this.csv_header_data = res.data.csv_header_data
                                this.table_header_data = res.data.table_header_data
                                this.headers = this.table_header_data

                                for(let i = 0;i<this.table_header_data.length;i++){
    let key = this.table_header_data[i].value
     this.filters[key] = []
}

                                if (this.memberWise.length == 0) {
                                    this.showSnackbar("#b71c1c", "No data available ");
                                }
                            }
                        })
                        .catch((error) => {
                            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                            window.console.log(error);
                        })
                        .finally(() => {});
                }
            },
            onLoad() {
                this.overlay = true;
                axios
                    .post("/ManagementAdmin/getdeptData")
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.overlay = false;
                            this.dept_data = res.data.dept_data;
                            this.orgList = res.data.orgList
                            this.usertype_data = res.data.usertype_data;
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        window.console.log(error);
                    })
                    .finally(() => {
                        // var overlay = false;
                    });
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            close() {
                this.dialog = false;
                // this.$refs.form.reset()
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },
        },
    };
</script>
<style scoped>
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    .v-icon-size {
        font-size: 20px;
    }
    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }
    .edit-avatar:hover {
        background-color: #b0bec5;
    }
    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }
    .edit-v-icon:hover {
        color: white;
    }
    .fields {
        padding: 0px !important;
    }
    .cus-card {
        background: #d3d3d33d;
        margin: 1rem;
        padding: 1rem;
        border: 2px solid gray;
    }
    .c-label {
        font-weight: bold;
    }
    .add-section {
        display: block;
        margin: 1rem;
    }
    .text >>> .v-input__slot {
        background: white !important;
        border-color: hsla(190, 99%, 30%, 1) !important;
    }
    .elevation-1 /deep/ tr {
        white-space: nowrap !important;
    }
    .d-btn {
        display: flex;
        justify-content: flex-end;
        margin: 0.5rem;
    }
        .checkbox{
        margin: 1rem;
    border: 1px solid;
    background: #d3d3d380;
    }
    .checkbox .box{
        margin-top: 5px;
    }
        .checkbox /deep/ .v-input--selection-controls{
        margin-top: 0px  !important;
    }
</style>
